.header {
    text-align: initial;
    font-size: 30px;
    // font-weight: lighter !important;
    font-family: Inter-Regular;
    color: #00287a;
  }
  .my-card {
    transition: transform 0.3s ease-in-out;
  }
  
  .my-card:hover {
    transform: scale(1.05); /* Example of scaling effect on hover */
  }