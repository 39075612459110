.login-root {
    height: 100%;
    background-color: white;
    overflow: hidden;
  
    .ant-input {
      min-height: 0;
      background: #f0f0f0 !important;
    }
  
    .left-content {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: initial;
      height: 100vh;
  
      .bg-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    @media (min-width : 1339px )
    {
      .mob-tab-header{
        display: none;
      }
      .header-cnt{
       display: none;
      }
    }
    
    //tablet
    @media(min-width : 600px) and (max-width : 1000px) {
      font-size: 14px !important;
      .right-btn {
        margin-top: 10px !important;
        margin-right: 100px !important;
        // margin-left: -5px !important;
        padding-left: 0px;
        float: left !important;
  
      }
  
      .header {
        padding-right: 300px;
        display: none;
      }
  
      .right-content {
        display: flex;
        justify-content: center;
        margin-inline: 38%;
        width: 30%;
        margin-top: 35px;
  
      }
      .subHeader{
        white-space: nowrap;
        padding-right: 180px;
      }
      .left-content {
        display: none;
      }
      .mob-tab-header{
        width: 100%;
        height: 45px;
        background-color: #082C7C
     }
     .header-cnt{
      color: white;
      font-family: 'Inter-Regular';
      font-weight: 500;
      padding-left:  20px;
      padding-top: 9px;
      font-size: 18px;
     }
    }
  
    //laptop small view 
    @media(min-width : 1000px) and (max-width : 1350px) {
      .left-content {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: initial;
        // height: 100vh;
        // width: 80%;
  
        .image-tab {
          width: 100%;
          object-fit: cover;
          object-position: 32% 0%;
        }
      }
      .mob-tab-header{
       display: none;
     }
     .header-cnt{
      display: none;
     }
  }
  
    //mobile
    @media(max-width : 600px) {
      .left-content {
        display: none;
      }
  
      .right-content {
        display: flex;
        justify-content: center;
        margin-inline: 38%;
        width: 30%;
        margin-top: 35px;
      }
      .header{
        display: none;
      }
      .right-btn {
        margin-top: 10px !important;
        margin-right: 100px !important;
        // margin-left: -5px !important;
        padding-left: 0px;
        float: left !important;
  
      }
      .mob-tab-header{
        width: 100%;
        height: 45px;
        background-color: #082C7C
     }
     .header-cnt{
      color: white;
      font-family: 'Inter-Regular';
      font-weight: 500;
      padding-left:  20px;
      padding-top: 9px;
      font-size: 18px;
     }
    }
  
    button.ant-btn.ant-btn-link.right-btn {
      float: right;
      margin-top: -26px;
      margin-right: 10px;
    }
  
  
  
    .subHeader {
      font-size: 20px;
      font-family: Inter-Regular;
      margin-bottom: 1rem;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      text-align: initial;
    }
  
    .ant-row.footer-content {
      bottom: 5px;
      position: fixed;
      width: 100%;
      left: 70%;
    }
  
    label.field-label {
      font-size: 12px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.6;
    }
  
    .form-builder .submit-btn {
      padding: 0 25px;
      border-radius: 8px;
      font-size: 12px;
      width: 7rem;
      height: 29px;
    }
  
    .header {
      text-align: initial;
      font-size: 30px;
      // font-weight: lighter !important;
      font-family: Inter-Regular;
      color: #00287a;
    }
  
    .ant-divider.ant-divider-horizontal.lower-div {
      margin-top: 0px;
      border-color: #C9C9C9;
      margin-bottom: auto;
    }
  
    button.ant-btn.ant-btn-link.left-pane {
      font-size: 10px;
      color: #0a6ed1;
      padding-left: 30px;
  
    }
  
    button.ant-btn.ant-btn-link.right-pane {
      font-size: 10px;
      color: #0a6ed1;
    }
  
    .ant-divider.ant-divider-horizontal.upper-div {
      margin-bottom: 3px;
      margin-top: auto;
      border-color: #C9C9C9;
    }
  
    .top-left {
      position: absolute;
      font-family: Inter-Regular;
      top: 5%;
      left: 5%;
      color: white;
      font-size: 20px;
      font-weight: lighter;
    }
  
    button#sign-in {
      background: #0a6ed1;
      text-align: center;
      background: #0A6ED1 0% 0% no-repeat padding-box;
      border: 1px solid #0A6ED1;
      border-radius: 6px;
      opacity: 1;
  
    }
  
  
    .ant-space-item {
      color: #00000080;
    }
  
    .ant-space.ant-space-horizontal.ant-space-align-center {
      display: inline-flex;
      margin-left: 0px;
      margin-top: 3px;
  
    }
  
    .ant-space.ant-space-horizontal.ant-space-align-center.copyright-part {
      margin-top: 6px;
      margin-left: 0px;
      font-size: 10px;
      padding: 4px 15px 0 30px;
    }
  
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background: #f0f0f0;
    }
  
    .ant-form-item-explain-error {
      color: #ff4d4f;
      padding-top: 6px;
   }
    button.ant-btn.ant-btn-link.copyright-btn {
      padding: 0px;
      font-size: 11px;
      color: #0a6ed1;
    }
  
    .ant-form-item-explain-error {
      text-align: start;
    }
  
    .ant-col {
      text-align: initial;
      padding: 0px 12px 0 0 !important;
    }
  
    .ant-row.login-content {
      height: 87vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .ant-input-affix-wrapper {
      background: #f0f0f0;
    }
  
    .formCenter {
      padding: 0 0 0 25px;
    }
  
    .forgetPassword {
      text-align: flex-end;
    }
  
    // button#forgot-password {
  
    // }
  
    button#forgot-password {
      // padding-left: 47px;
      right: 35px;
    }
  
    .buttonGroup {
      display: flex;
      justify-content: space-between;
      width: 80%;
    }
  
    .btn {
      padding: 0 5%;
    }
  
    .bottomTab {
      width: 100%;
      height: 3rem;
      border: 1px solid gray;
      bottom: 0;
      position: absolute;
    }
  
    .formField {
      margin-bottom: 40px;
    }
  
    .formFieldLabel {
      color: white;
    }
  
    .formFieldInput {
      color: white;
      border-bottom: 1px solid #fafbfc;
    }
  
  
    .ant-image {
      display: block;
    }
  
    .img {
      height: 100vh;
      width: 100%;
    }
  
  
    // .item {
    //   margin-top: 150px;
    //   margin-right: -50px;
    //   margin-left: 20px;
    // }
  
    .item input {
      width: 350px;
  
    }
  
    .item label {
      margin-top: 10px;
    }
  
    .h1 {
      margin-top: 175px;
      margin-bottom: 0.5em;
      color: #3F5C9A;
      font-weight: 500;
      padding-right: 150px;
    }
  
    .h2 {
      margin-top: 35px;
      margin-bottom: 0.5em;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      padding-right: 80px;
    }
  
  }
  
  
  .modal-class {
    .steps-content {
      min-height: 260px;
      margin-top: 16px;
      padding-top: 44px;
      text-align: center;
      background-color: #9dbbd729;
      border: 1px #e9e9e9;
      border-radius: 2px;
  
      .ant-form-item-control-input {
        width: 62% !important;
      }
  
      .ant-row.ant-form-item {
        align-items: center;
        /* margin-left: 163px; */
        justify-content: center;
        margin-top: 17px;
      }
  
      .new-password {
        margin-top: -26px !important;
      }
  
      .ant-form-item-with-help .ant-form-item-explain {
        width: fit-content;
      }
  
      h1.lock-icon {
        position: absolute;
        top: 38%;
        left: 47%;
        font-size: xx-large;
      }
  
      .ant-form-item-control-input {
        width: 100% !important;
      }
  
  
    }
  
    .steps-action {
      margin-top: 24px;
    }
  
    // .ant-modal-content {
    //   width: 105vh;
  
    //   // right: 7vh;
    // }
  
    .ant-modal-body {
      // width: 105vh;
      padding: 17px;
    }
  
    .ant-modal-footer {
      display: none;
    }
  
  
  
    .done-message {
  
      align-items: center;
      justify-content: center;
  
    }
  
    h1.smile-icon {
      color: #2aeb2a;
      font-size: xx-large;
    }
  
    button.ant-btn.ant-btn-primary.validate-btn {
      margin-top: 2px;
    }
  
    h3.mail-text {
      margin-top: -37px;
      margin-left: -14px;
  
    }
  
    form#otp {
      margin-top: 35px;
    }
  
    .ant-modal {
      position: absolute !important;
      top: 24% !important;
      left: 23%;
    }
  
    p.password-reset {
      margin-top: 43px;
    }
  
    h4.create-new-password {
      margin-top: 17px;
    }
  
    .ant-form-item-explain-error {
      color: #ff4d4f;
      font-size: 11px;
    }
  
  }